import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken, getUserInfoFromToken } from '../services/authService';

export default function ProtectedRoute({ children, allowedRoles }) {
  const token = getToken();

  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const { role } = getUserInfoFromToken();

    if (allowedRoles && !allowedRoles.includes(role)) {
      return <Navigate to="/login" />;
    }

    return children;
  } catch (error) {
    console.error('Invalid token:', error.message);
    return <Navigate to="/login" />;
  }
}
