import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { dataGridStyles } from '../styles';
import Loader from '../components/Loader';
import axios from 'axios';
import dayjs from 'dayjs';
import { getUserInfoFromToken } from '../services/authService';

const TrashHoldDomains = () => {
  const { role, username, team } = useMemo(() => getUserInfoFromToken(), []);

  const [allDomains, setAllDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const stringOnlyContainsOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');

  useEffect(() => {
    const fetchDomainsData = async () => {
      setLoading(true);
      try {
        const params = {
          role,
          username,
        };

        if (role === 'tlead' && Array.isArray(team)) {
          params.team = JSON.stringify(team);
        }

        const response = await axios.get('https://backend.moorpan.com/get-trash-hold-domains', {
          params,
          headers: {
            role,
            username,
          },
        });

        const updatedData = Object.entries(response.data).reduce((acc, [project, domains]) => {
          const filteredDomains = domains.map((domain) => ({ ...domain, project }));

          if (filteredDomains.length > 0) {
            acc[project] = filteredDomains;
          }

          return acc;
        }, {});

        setAllDomains(updatedData);
      } catch (err) {
        setError('Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchDomainsData();
  }, [role, username, team]);

  const calculateLifeDuration = (dateChecked, dateDead) => {
    if (!dateChecked) return null;
    if (dateDead !== null) {
      const now = dayjs(dateDead);
      const checkedDate = dayjs(dateChecked);
      return now.diff(checkedDate, 'day');
    }
    const now = dayjs();
    const checkedDate = dayjs(dateChecked);
    return now.diff(checkedDate, 'day');
  };

  const getRowClassName = (lifeDuration) => {
    if (lifeDuration === null) return '';
    if (lifeDuration <= 7) return 'row-red';
    if (lifeDuration <= 14) return 'row-yellow';
    if (lifeDuration <= 31) return 'row-orange';
    return 'row-green';
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Typography variant="h6" color="error" align="center">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Trash Hold Domains
      </Typography>

      <Box sx={{ marginBottom: 4 }}>
        <DataGrid
          rows={Object.values(allDomains).flat()}
          columns={[
            { field: 'domain', headerName: 'Domain', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'project', headerName: 'Buyer', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'status', headerName: 'Status', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'status_ads', headerName: 'Status Ads', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            {
              field: 'date_checked',
              headerName: 'Date start index',
              flex: 1,
              type: 'dateTime',
              valueGetter: (params) => {
                const dateChecked = params;
                return dateChecked ? new Date(dateChecked) : null;
              },
              sortable: false,
              filterOperators: stringOnlyContainsOperators,
            },
            {
              field: 'date_dead',
              headerName: 'Date end index',
              flex: 1,
              type: 'dateTime',
              valueGetter: (params) => {
                const dateDead = params;
                return dateDead ? new Date(dateDead) : null;
              },
              sortable: false,
              filterOperators: stringOnlyContainsOperators,
            },
            {
              field: 'lifeDuration',
              headerName: 'Life Duration (days)',
              flex: 1,
              renderCell: (cellValues) => {
                if (!cellValues || !cellValues.row) {
                  return "No Data";
                }
                const dateChecked = cellValues.row.date_checked;
                const dateDead = cellValues.row.date_dead || null;
                return dateChecked ? calculateLifeDuration(dateChecked, dateDead) : "No Data";
              },
              sortable: false,
              filterOperators: stringOnlyContainsOperators,
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100, 250, { value: -1, label: 'All' }]}
          getRowId={(row) => row.domain}
          getRowClassName={(params) => {
            if (!params || !params.row) {
              return '';
            }
            const dateChecked = params.row?.date_checked;
            const dateDead = params.row?.date_dead || null;
            if (!dateChecked) {
              return '';
            }
            return getRowClassName(calculateLifeDuration(dateChecked, dateDead));
          }}
          sx={dataGridStyles}
        />
      </Box>
    </Box>
  );
};

export default TrashHoldDomains;
