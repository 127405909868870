export const dataGridStyles = {
  '& .MuiDataGrid-cell': {
    color: '#1e1111',
    fontWeight: '700',
    backgroundColor: '#fdfeff',
    textOverflow: 'unset',
  },
  '& .MuiDataGrid-cell:not(:last-child)': {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  '& .MuiDataGrid-even-row .MuiDataGrid-cell': {
    color: '#1e1111',
    fontWeight: '700',
    backgroundColor: '#f8f9fc',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#fdfeff',
    fontWeight: '700',
  },
  '& .MuiDataGrid-row:hover .MuiDataGrid-cell': {
    backgroundColor: '#C8D2D1',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#1e1111',
  },
  '& .MuiIconButton-root.MuiDataGrid-menuIconButton': {
    color: '#fff',
  },
  '& .row-red .MuiDataGrid-cell': {
    backgroundColor: '#ffcccc',
  },
  '& .row-yellow .MuiDataGrid-cell': {
    backgroundColor: '#ffffcc',
  },
  '& .row-orange .MuiDataGrid-cell': {
    backgroundColor: '#ffe5cc',
  },
  '& .row-green .MuiDataGrid-cell': {
    backgroundColor: '#ccffcc',
  },
  '& .lifetime-account-header': {
    borderLeft: '5px solid rgba(255, 255, 255, 1)',
    paddingLeft: '16px',
  },
  '& .lifetime-account-cell': {
    borderLeft: '5px solid rgba(30, 30, 30, 1)',
    paddingLeft: '16px',
  },
  '& .lifetime-domain-header': {
    paddingLeft: '16px',
  },
  '& .lifetime-domain-cell': {
    paddingLeft: '16px',
  },
  '& .MuiDataGrid-sortIcon': {
    fill: 'white',
  },
  '& .checked-row-custom .MuiDataGrid-cell': {
    backgroundColor: '#141212',
    color: '#e8eaed',
  },
  '& .checked-row-custom:hover .MuiDataGrid-cell': {
    backgroundColor: '#141212',
    color: '#e8eaed',
  },
  '& [data-id="total"] & .MuiDataGrid-cell': {
    backgroundColor: '#ccffcc',
  },
  '& [data-id="geo-total"] & .MuiDataGrid-cell': {
    backgroundColor: '#ccffcc',
  },
  '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-row .MuiDataGrid-cell': {
    backgroundColor: '#7a8189',
  },
  '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-cell': {
    color: '#e8eaed',
    fontWeight: '600',
  },
  '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-row:hover .MuiDataGrid-cell': {
    backgroundColor: '#77787a',
  },
  '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-even-row .MuiDataGrid-cell': {
    backgroundColor: '#5f6062',
    color: '#e8eaed',
    fontWeight: '600',
  },
  '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-cell:not(:last-child)': {
    borderRight: '1px solid #5f6368',
  },

  '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-columnHeader': {
    backgroundColor: '#797979',
  },

  '[data-toolpad-color-scheme="dark"] & .row-green .MuiDataGrid-cell': {
    backgroundColor: '#1ab394',
  },

  '@media (hover: hover)': {
    '& .MuiDataGrid-row:hover .MuiDataGrid-cell': {
      backgroundColor: '#C8D2D1',
    },
    '& .checked-row-custom:hover .MuiDataGrid-cell': {
      backgroundColor: '#141212',
      color: '#e8eaed',
    },
    '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-row:hover .MuiDataGrid-cell': {
      backgroundColor: '#77787a',
    },
  },

  '@media (hover: none)': {
    '& .MuiDataGrid-row:active .MuiDataGrid-cell': {
      backgroundColor: '#C8D2D1',
    },
    '& .checked-row-custom:active .MuiDataGrid-cell': {
      backgroundColor: '#141212',
      color: '#e8eaed',
    },
    '[data-toolpad-color-scheme="dark"] & .MuiDataGrid-row:active .MuiDataGrid-cell': {
      backgroundColor: '#77787a',
    },
  },
  
  /* DataGridStyles.css */
};

