import { jwtDecode } from 'jwt-decode';

export const register = async (email, password, role, username, team = null) => {
  const response = await fetch('https://backend.moorpan.com/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, role, username, team }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || 'Registration failed');
  }

  return data;
};

export const login = async (email, password) => {
  const response = await fetch('https://backend.moorpan.com/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Login failed');
  }

  localStorage.setItem('authToken', data.token);

  return data.token;
};

export const logout = () => {
  localStorage.removeItem('authToken');
};

export const getToken = () => {
  return localStorage.getItem('authToken');
};

export const getUserInfoFromToken = () => {
  const token = getToken();
  if (!token) {
    return { role: null, username: null, team: null };
  }

  const decoded = jwtDecode(token);
  const { role, username, team } = decoded;
  return {
    role: role || null,
    username: username || null,
    team: team || null
  };
};
