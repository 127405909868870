import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { dataGridStyles } from "../styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getUserInfoFromToken } from "../services/authService";

// Вынесем за компонент, чтобы не пересоздавался на каждый рендер
const RED_STATUSES = [
  "обход системы",
  "бизнес модель",
  "бизнес практика",
  "компрометирующий сайт",
];

export default function FinancialAnalytics() {
  const { role, username, team } = useMemo(() => getUserInfoFromToken(), []);

  const [financeData, setFinanceData] = useState([]);
  const [accountFinData, setAccountFinData] = useState([]);
  const [loadingFinance, setLoadingFinance] = useState(true);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogRows, setDialogRows] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [selectedBuyers, setSelectedBuyers] = useState([]);

  const [accountStartDate, setAccountStartDate] = useState(dayjs().startOf("month"));
  const [accountEndDate, setAccountEndDate] = useState(dayjs().endOf("month"));

  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs().endOf("month"));

  const [emailFilter, setEmailFilter] = useState("");
  const [domainFilter, setDomainFilter] = useState("");
  const [idAccountFilter, setIdAccountFilter] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [buyerOptions, setBuyerOptions] = useState([]);
  const [selectedBuyerFilters, setSelectedBuyerFilters] = useState([]);

  const [checkedRows, setCheckedRows] = useState({});
  const [checkboxLoading, setCheckboxLoading] = useState({});

  const [dialogSelectedStatuses, setDialogSelectedStatuses] = useState([]);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showOnlyChecked, setShowOnlyChecked] = useState(false);
  const [showOnlyRedStatuses, setShowOnlyRedStatuses] = useState(false);

  const [pageSize, setPageSize] = useState(25);

  const isFinance = role === "finance";
  const isUser = role === "user";
  const isTlead = role === "tlead";

  // NEW: Добавляем состояния для чекбоксов фильтрации по байерам в "Financial Data"
  const [financeBuyers, setFinanceBuyers] = useState([]);
  const [selectedFinanceBuyers, setSelectedFinanceBuyers] = useState([]);

  const handleFinanceBuyerCheckboxChange = (buyer) => {
    setSelectedFinanceBuyers((prev) =>
      prev.includes(buyer) ? prev.filter((b) => b !== buyer) : [...prev, buyer]
    );
  };

  const handleFinanceShowAll = () => {
    setSelectedFinanceBuyers(financeBuyers);
  };

  const handleFinanceHideAll = () => {
    setSelectedFinanceBuyers([]);
  };

  const handleFullDate = useCallback(() => {
    setAccountStartDate(null);
    setAccountEndDate(null);
  }, []);

  const handleRefresh = useCallback(() => {
    setAccountStartDate(dayjs().startOf("month"));
    setAccountEndDate(dayjs().endOf("month"));
    setEmailFilter("");
    setDomainFilter("");
    setIdAccountFilter("");
    setSelectedStatuses([]);
    setSelectedBuyerFilters([]);
    fetchFinanceData();
    fetchAccountFinData();
    // eslint-disable-next-line
  }, []);

  const financeColumns = useMemo(() => [
    { field: "seller", headerName: "Seller", minWidth: 150, flex: 1 },
    {
      field: "account_balance",
      headerName: "Total Balance",
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => (params ? params : "0.00"),
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
    {
      field: "current_total_balance",
      headerName: "Current Total Balance",
      minWidth: 200,
      flex: 1,
      valueGetter: (params, row) => {
        const accountBalance = Number(row.account_balance ?? 0);
        const cost = Number(row.cost ?? 0);
        return (accountBalance - cost).toFixed(2);
      },
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
    {
      field: "cost",
      headerName: "Total Spend",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => (params ? params : "0.00"),
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
    {
      field: "detail",
      headerName: "Detail",
      minWidth: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => handleDetailClick(params)}
        >
          Detail
        </Button>
      ),
    },
  ], []);

  const accountFinColumns = useMemo(() => [
    {
      field: "dateOfIssue",
      headerName: "Date of Issue",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) =>
        params ? dayjs(params).format("DD/MM/YYYY") : "",
    },
    { field: "project", headerName: "Buyer", minWidth: 150, flex: 1 },
    { field: "email", headerName: "Email", minWidth: 200, flex: 1 },
    { field: "seller", headerName: "Seller", minWidth: 150, flex: 1 },
    {
      field: "domain",
      headerName: "Domain",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "accountType",
      headerName: "Account Type",
      minWidth: 150,
      flex: 1,
    },
    { field: "idAccount", headerName: "ID Account", minWidth: 200, flex: 1 },
    { field: "statusAds", headerName: "Status ADS", minWidth: 150, flex: 1 },
    {
      field: "accBalance",
      headerName: "Account Balance ($)",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) =>
        params ? Number(params).toFixed(2) : "0.00",
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
    {
      field: "totalCost",
      headerName: "Total Spend ($)",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) =>
        params ? Number(params).toFixed(2) : "0.00",
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
  ], []);

  useEffect(() => {
    if (!selectedRow || financeData.length === 0) {
      return;
    }

    let filtered = [...financeData];

    if (isUser) {
      filtered = filtered.filter((data) => data.buyer === username);
    } else if (isTlead) {
      filtered = filtered.filter(
        (data) => data.buyer === username || (team && team.includes(data.buyer))
      );
    }

    if (accountStartDate && accountEndDate) {
      filtered = filtered.filter((data) =>
        dayjs(data.date).isBetween(accountStartDate, accountEndDate, null, "[]")
      );
    }

    filtered = filtered.filter((data) => data.seller === selectedRow.seller);

    const filteredRows = filtered.reduce((acc, data) => {
      const formattedDate = dayjs(data.date).format("DD/MM/YYYY");

      if (!acc[data.id_account]) {
        acc[data.id_account] = {
          id_account: data.id_account || `detail-${data.id_account}`,
          buyer: data.buyer,
          email: data.email,
          account_total_balance: data.account_balance || 0,
          account_spend: 0,
          account_balance: data.account_balance || 0,
          status: data.status,
          costs: {},
          is_checked: data.is_checked,
        };
      }

      acc[data.id_account].costs[formattedDate] =
        (acc[data.id_account].costs[formattedDate] || 0) + (Number(data.cost) || 0);

      return acc;
    }, {});


    const rows = Object.values(filteredRows).map((account) => {
      const r = {
        id_account: account.id_account,
        buyer: account.buyer,
        email: account.email,
        account_total_balance: Number(account.account_total_balance).toFixed(2),
        account_spend: "0.00",
        account_balance: Number(account.account_balance).toFixed(2),
        status: account.status,
        is_checked: account.is_checked,
      };

      let currentDate = startDate.clone();
      while (currentDate.isSameOrBefore(endDate, "day")) {
        let dateStr = currentDate.format("DD/MM/YYYY");
        let cost = account.costs[dateStr] || 0;
        if (cost > 0) {
          r[dateStr] = cost.toFixed(2);
          r.account_balance = (Number(r.account_balance) - cost).toFixed(2);
          r.account_spend = (Number(r.account_spend) + cost).toFixed(2);
        } else {
          r[dateStr] = "";
        }
        currentDate = currentDate.add(1, "day");
      }

      return {
        ...r,
        id_account: r.id_account || `detail-row-${Math.random()}`,
      };
    });

    const initialChecked = rows.reduce((acc, r) => {
      if (r.is_checked) {
        acc[r.id_account] = true;
      }
      return acc;
    }, {});

    setCheckedRows(initialChecked);
    setDialogRows(rows);
  }, [
    selectedRow,
    financeData,
    startDate,
    endDate,
    accountStartDate,
    accountEndDate,
    isUser,
    isTlead,
    username,
    team,
  ]);

  const handleDetailClick = useCallback((params) => {
    setSelectedRow(params.row);
    setOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpenDialog(false);
    setSelectedRow(null);
    setCheckedRows({});
  }, []);

  useEffect(() => {
    const uniqueBuyers = [...new Set(dialogRows.map((row) => row.buyer))];
    setBuyers(uniqueBuyers);
    setSelectedBuyers(uniqueBuyers);
  }, [dialogRows]);

  const handleBuyerCheckboxChange = useCallback((buyer) => {
    setSelectedBuyers((prevSelected) => {
      if (prevSelected.includes(buyer)) {
        return prevSelected.filter((b) => b !== buyer);
      } else {
        return [...prevSelected, buyer];
      }
    });
  }, []);

  const handleShowAll = useCallback(() => {
    setSelectedBuyers(buyers);
  }, [buyers]);

  const handleHideAll = useCallback(() => {
    setSelectedBuyers([]);
  }, []);

  const handleRowCheckboxChange = useCallback(async (idAccount) => {
    if (!isFinance) return;

    const row = dialogRows.find((r) => r.id_account === idAccount);
    if (!row) {
      console.error("Row not found for id_account:", idAccount);
      return;
    }

    const isCurrentlyChecked = !!checkedRows[idAccount];
    const newCheckedStatus = !isCurrentlyChecked;

    setCheckboxLoading((prev) => ({
      ...prev,
      [idAccount]: true,
    }));

    try {
      await axios.post(
        "https://backend.moorpan.com/set-checked",
        {
          id_account: idAccount,
          isChecked: newCheckedStatus,
        },
        {
          headers: {
            role: role,
            username: username,
          },
        }
      );

      setCheckedRows((prev) => ({
        ...prev,
        [idAccount]: newCheckedStatus,
      }));
    } catch (error) {
      console.error("Не удалось обновить статус:", error);
    } finally {
      setCheckboxLoading((prev) => ({
        ...prev,
        [idAccount]: false,
      }));
    }
  }, [dialogRows, role, username, isFinance, checkedRows]);

  const filteredDialogRows = useMemo(() => {
    const rows = dialogRows
      .filter((row) => selectedBuyers.includes(row.buyer))
      .map((row) => ({
        ...row,
        className: checkedRows[row.id_account] ? "checked-row-custom" : "",
      }));

    rows.sort((a, b) => {
      const aChecked = !!checkedRows[a.id_account];
      const bChecked = !!checkedRows[b.id_account];
      return aChecked === bChecked ? 0 : aChecked ? 1 : -1;
    });

    return rows;
  }, [dialogRows, selectedBuyers, checkedRows]);

  const fetchFinanceData = useCallback(async () => {
    setLoadingFinance(true);
    try {
      const response = await axios.get("https://backend.moorpan.com/finance", {
        headers: {
          role: role,
          username: username,
          team: team ? JSON.stringify(team) : "[]",
        },
      });
      let processedData = response.data.map((row, index) => ({
        ...row,
        id_account: row.id_account || `finance-${index}`,
      }));

      if (isUser) {
        processedData = processedData.filter((r) => r.buyer === username);
      } else if (isTlead) {
        processedData = processedData.filter(
          (r) => r.buyer === username || (team && team.includes(r.buyer))
        );
      }

      setFinanceData(processedData);
      return processedData;
    } catch (error) {
      console.error("Ошибка при загрузке финансовых данных:", error);
    } finally {
      setLoadingFinance(false);
    }
  }, [role, username, team, isUser, isTlead]);

  const fetchAccountFinData = useCallback(async () => {
    setLoadingAccounts(true);
    try {
      const response = await axios.get("https://backend.moorpan.com/get-account-fin", {
        headers: {
          role: role,
          username: username,
        },
      });
      let processedData = response.data.map((row, index) => ({
        ...row,
        id_account: row.idAccount || `account-fin-${index}`,
      }));

      if (isUser) {
        processedData = processedData.filter((r) => r.project === username);
      } else if (isTlead) {
        processedData = processedData.filter(
          (r) => r.project === username || (team && team.includes(r.project))
        );
      }

      setAccountFinData(processedData);

      const statuses = [
        ...new Set(processedData.map((row) => row.statusAds).filter(Boolean)),
      ];
      const buyers = [
        ...new Set(processedData.map((row) => row.project).filter(Boolean)),
      ];
      setStatusOptions(statuses);
      setBuyerOptions(buyers);
    } catch (error) {
      console.error("Ошибка при загрузке данных аккаунтов:", error);
    } finally {
      setLoadingAccounts(false);
    }
  }, [role, username, isUser, isTlead, team]);

  const groupedData = useMemo(() => {
    let filteredFinanceData = [...financeData];
  
    if (accountStartDate && accountEndDate) {
      filteredFinanceData = filteredFinanceData.filter((row) =>
        dayjs(row.date).isBetween(accountStartDate, accountEndDate, null, "[]")
      );
    }
  
    if (selectedFinanceBuyers.length > 0) {
      filteredFinanceData = filteredFinanceData.filter((row) =>
        selectedFinanceBuyers.includes(row.buyer)
      );
    }
  
    const grouped = {};
  
    filteredFinanceData.forEach((row) => {
      if (!grouped[row.seller]) {
        grouped[row.seller] = {
          seller: row.seller,
          buyer: row.buyer,
          email: row.email,
          cost: 0,
          account_balance: 0,
          uniqueAccountsForBalance: new Set(),
        };
      }
  
      grouped[row.seller].cost += Number(row.cost) || 0;
  
      if (
        row.id_account &&
        !grouped[row.seller].uniqueAccountsForBalance.has(row.id_account)
      ) {
        grouped[row.seller].account_balance += Number(row.account_balance) || 0;
        grouped[row.seller].uniqueAccountsForBalance.add(row.id_account);
      }
    });
  
    return Object.values(grouped).map((row) => ({
      seller: row.seller,
      buyer: row.buyer,
      email: row.email,
      cost: row.cost.toFixed(2),
      account_balance: row.account_balance.toFixed(2),
      id: `seller-${row.seller}`,
    }));
  }, [financeData, accountStartDate, accountEndDate, selectedFinanceBuyers]);
  

  useEffect(() => {
    const uniqueBuyers = [...new Set(financeData.map((row) => row.buyer))];
    setFinanceBuyers(uniqueBuyers);
    setSelectedFinanceBuyers(uniqueBuyers);
  }, [financeData]);

  const filteredGroupedData = useMemo(() => {
    return groupedData.filter((row) => selectedFinanceBuyers.includes(row.buyer));
  }, [groupedData, selectedFinanceBuyers]);

  const filteredAccountFinData = useMemo(() => {
    let filteredData = [...accountFinData];

    if (accountStartDate && accountEndDate) {
      filteredData = filteredData.filter((account) => {
        const dateOfIssue = dayjs(account.dateOfIssue);
        return dateOfIssue.isBetween(
          accountStartDate,
          accountEndDate,
          "day",
          "[]"
        );
      });
    }

    if (emailFilter) {
      filteredData = filteredData.filter((account) =>
        account.email?.includes(emailFilter)
      );
    }

    if (domainFilter) {
      filteredData = filteredData.filter((account) =>
        account.domain?.includes(domainFilter)
      );
    }

    if (idAccountFilter) {
      filteredData = filteredData.filter((account) =>
        account.idAccount?.includes(idAccountFilter)
      );
    }

    if (selectedStatuses.length > 0) {
      filteredData = filteredData.filter((account) =>
        selectedStatuses.includes(account.statusAds)
      );
    }

    if (selectedBuyerFilters.length > 0) {
      filteredData = filteredData.filter((account) =>
        selectedBuyerFilters.includes(account.project)
      );
    }

    filteredData = filteredData.filter(
      (account) => Number(account.accBalance) > 0
    );

    return filteredData.map((row, index) => ({
      ...row,
      id: row.id_account || `account-${index}`,
    }));
  }, [
    accountFinData,
    accountStartDate,
    accountEndDate,
    emailFilter,
    domainFilter,
    idAccountFilter,
    selectedStatuses,
    selectedBuyerFilters,
  ]);

  const dialogStatusOptions = useMemo(() => {
    const allStatuses = dialogRows.map((r) => r.status).filter(Boolean);
    const combined = [...new Set([...allStatuses, ...RED_STATUSES])];
    return combined;
  }, [dialogRows]);

  const filteredDialogRowsWithStatus = useMemo(() => {
    let rows = filteredDialogRows;
    if (dialogSelectedStatuses.length > 0) {
      rows = rows.filter((row) => dialogSelectedStatuses.includes(row.status));
    }
    if (showOnlyChecked) {
      rows = rows.filter((row) => checkedRows[row.id_account]);
    }
    if (showOnlyRedStatuses) {
      rows = rows.filter((row) => RED_STATUSES.includes(row.status));
    }
    return rows;
  }, [filteredDialogRows, dialogSelectedStatuses, showOnlyChecked, showOnlyRedStatuses, checkedRows]);

  const dialogColumns = useMemo(() => {
    const dateRange = [];
    let currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate, "day")) {
      dateRange.push(currentDate.format("DD/MM/YYYY"));
      currentDate = currentDate.add(1, "day");
    }

    let baseCols = [
      ...(isFinance
        ? [{
            field: "checkbox",
            headerName: "",
            width: 50,
            renderCell: (params) => (
              <Checkbox
                checked={!!checkedRows[params.row.id_account]}
                onChange={() => handleRowCheckboxChange(params.row.id_account)}
                disabled={!!checkboxLoading[params.row.id_account]}
              />
            ),
          }]
        : []),
      { field: "buyer", headerName: "Buyer", minWidth: 150, flex: 1 },
      { field: "email", headerName: "Email", minWidth: 200, flex: 1, sortable: false },
      { field: "id_account", headerName: "ID Account", minWidth: 200, flex: 1, sortable: false },
      {
        field: "status",
        headerName: "Status ADS",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "account_total_balance",
        headerName: "Account Balance - top up (total)",
        minWidth: 150,
        flex: 1,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "account_spend",
        headerName: "Spend (total)",
        minWidth: 150,
        flex: 1,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "account_balance",
        headerName: "Current balance",
        minWidth: 150,
        flex: 1,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      ...dateRange.map((date) => ({
        field: date,
        headerName: date,
        minWidth: 150,
        flex: 1,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      })),
    ];


    baseCols = baseCols.map((col) => {
      if (col.field !== "checkbox") {
        return {
          ...col,
          renderHeader: (params) => {
            if (!isFinance) {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {params.colDef.headerName}
                </Box>
              );
            }

            const checked = selectedColumns.includes(params.colDef.field);
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    let newSelected = [...selectedColumns];
                    if (isChecked) {
                      if (!newSelected.includes(params.colDef.field)) {
                        newSelected.push(params.colDef.field);
                      }
                    } else {
                      newSelected = newSelected.filter(f => f !== params.colDef.field);
                    }
                    setSelectedColumns(newSelected);
                  }}
                />
                {params.colDef.headerName}
              </Box>
            );
          }
        };
      }
      return col;
    });

    return baseCols;
  }, [startDate, endDate, isFinance, selectedColumns, checkedRows, checkboxLoading, handleRowCheckboxChange]);

  useEffect(() => {
    fetchFinanceData();
    fetchAccountFinData();
  }, [fetchFinanceData, fetchAccountFinData]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ padding: "16px" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Financial Analytics
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
            mt: 2,
          }}
        >
          <DatePicker
            label="Start Date"
            value={accountStartDate}
            onChange={(date) => setAccountStartDate(date)}
            sx={{ width: "22%" }}
            slotProps={{ textField: { variant: "outlined" } }}
          />
          <DatePicker
            label="End Date"
            value={accountEndDate}
            onChange={(date) => setAccountEndDate(date)}
            sx={{ width: "22%" }}
            slotProps={{ textField: { variant: "outlined" } }}
          />
          <Button
            variant="contained"
            onClick={handleFullDate}
            sx={{ width: "22%" }}
          >
            Full Date
          </Button>
          <IconButton
            onClick={handleRefresh}
            color="primary"
            sx={{ marginLeft: "12px" }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h5" gutterBottom>
            Financial Data
          </Typography>

          {/* NEW: Блок с чекбоксами фильтрации по buyer */}
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Button onClick={handleFinanceShowAll} sx={{ mr: 2 }}>
                Show All
              </Button>
              <Button onClick={handleFinanceHideAll}>Hide All</Button>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", mb: 2 }}>
              {financeBuyers.map((buyer) => (
                <FormControlLabel
                  key={buyer}
                  control={
                    <Checkbox
                      checked={selectedFinanceBuyers.includes(buyer)}
                      onChange={() => handleFinanceBuyerCheckboxChange(buyer)}
                    />
                  }
                  label={buyer}
                />
              ))}
            </Box>
          </Box>
          {/* Конец NEW */}

          {loadingFinance ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={filteredGroupedData}
              columns={financeColumns}
              getRowId={(row) => row.id}
              pageSizeOptions={[25, 50, 100]}
              sx={dataGridStyles}
            />
          )}
        </Box>

        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h5" gutterBottom>
            Accounts Financial Data
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
              mt: 2,
            }}
          >
            <TextField
              label="Domain"
              value={domainFilter}
              onChange={(e) => setDomainFilter(e.target.value)}
              sx={{ width: "19%" }}
            />
            <TextField
              label="Email"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
              sx={{ width: "19%" }}
            />
            <TextField
              label="ID Account"
              value={idAccountFilter}
              onChange={(e) => setIdAccountFilter(e.target.value)}
              sx={{ width: "19%" }}
            />

            <Autocomplete
              multiple
              options={statusOptions}
              value={selectedStatuses}
              sx={{ width: "19%" }}
              onChange={(event, newValue) => {
                setSelectedStatuses(newValue);
              }}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  sx={{ width: "100%" }}
                  variant="outlined"
                />
              )}
              clearOnEscape
            />
            <Autocomplete
              multiple
              options={buyerOptions}
              value={selectedBuyerFilters}
              sx={{ width: "19%" }}
              onChange={(event, newValue) => {
                setSelectedBuyerFilters(newValue);
              }}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buyers"
                  sx={{ width: "100%" }}
                  variant="outlined"
                />
              )}
              clearOnEscape
            />
          </Box>

          {loadingAccounts ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={filteredAccountFinData}
              columns={accountFinColumns}
              getRowId={(row) => row.id}
              pageSizeOptions={[25, 50, 100]}
              sx={dataGridStyles}
              initialState={{
                sorting: {
                  sortModel: [{ field: "dateOfIssue", sort: "desc" }],
                },
              }}
            />
          )}
        </Box>

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Details for Seller: {selectedRow?.seller}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mt: 2,
              }}
            >
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                sx={{ width: "48%" }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                sx={{ width: "48%" }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Button onClick={handleShowAll} sx={{ mr: 2 }}>
                  Show All
                </Button>
                <Button onClick={handleHideAll}>Hide All</Button>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", mb: 2 }}>
                {buyers.map((buyer) => (
                  <FormControlLabel
                    key={buyer}
                    control={
                      <Checkbox
                        checked={selectedBuyers.includes(buyer)}
                        onChange={() => handleBuyerCheckboxChange(buyer)}
                      />
                    }
                    label={buyer}
                  />
                ))}
              </Box>
              <Autocomplete
                multiple
                options={dialogStatusOptions}
                value={dialogSelectedStatuses}
                onChange={(event, newValue) => setDialogSelectedStatuses(newValue)}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Status"
                    variant="outlined"
                    sx={{ width: "100%" }}
                  />
                )}
                clearOnEscape
                sx={{ mb: 2 }}
              />
              {isFinance && (
                <Box>
                  <Button
                    onClick={() => setShowOnlyChecked((prev) => !prev)}
                    sx={{ ml: 2 }}
                    variant="outlined"
                  >
                    {showOnlyChecked ? "Show All Rows" : "Show Only Checked Rows"}
                  </Button>
                  <Button
                    onClick={() => setShowOnlyRedStatuses((prev) => !prev)}
                    sx={{ ml: 2 }}
                    variant="outlined"
                    color={showOnlyRedStatuses ? "error" : "primary"}
                  >
                    {showOnlyRedStatuses ? "Show All Statuses" : "Show Only Red Statuses"}
                  </Button>
                </Box>
              )}
            </Box>

            <DataGrid
              rows={filteredDialogRowsWithStatus}
              columns={dialogColumns}
              getRowId={(row) => row.id_account || `dialog-${row.id_account}`}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => {
                if (newPageSize === -1) {
                  setPageSize(filteredDialogRowsWithStatus.length);
                } else {
                  setPageSize(newPageSize);
                }
              }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[25, 50, 100, { value: -1, label: "All" }]}
              sx={(theme) => {
                const baseStyles = {
                  ...dataGridStyles,
                  '& .MuiDataGrid-cell': {
                    userSelect: 'none',
                    color: '#1e1111',
                    fontWeight: '700',
                    backgroundColor: '#fdfeff',
                    textOverflow: 'unset',
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root': {
                    color: 'white'
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiBox-root': {
                    color: 'white',
                    fontWeight: 500,
                  },
                };

                selectedColumns.forEach((field) => {
                  baseStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
                    userSelect: 'text',
                  };
                });

                return baseStyles;
              }}

              getRowClassName={(params) => {
                const isChecked = !!checkedRows[params.row.id_account];
                const isRedStatus = RED_STATUSES.includes(params.row.status);

                if (isChecked) {
                  return "checked-row-custom";
                }

                if (isRedStatus) {
                  return "row-red";
                }

                return params.indexRelativeToCurrentPage % 2 === 0
                  ? "MuiDataGrid-even-row"
                  : "";
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
}
