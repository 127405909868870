import React, { useState } from 'react';
import { AppProvider, SignInPage } from '@toolpad/core';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/authService';
import Loader from '../components/Loader';

const providers = [{ id: 'credentials', name: 'Email and Password' }];

const signIn = async (provider, formData, navigate, setLoading) => {
  setLoading(true);
  try {
    const email = formData.get('email');
    const password = formData.get('password');

    const token = await login(email, password);
    
    navigate('/');
  } catch (error) {
    alert('Login failed: ' + error.message);
  } finally {
    setLoading(false);
  }
};

export default function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <AppProvider theme={theme}>
      {loading && <Loader />}
      <SignInPage signIn={(provider, formData) => signIn(provider, formData, navigate, setLoading)} providers={providers} />
    </AppProvider>
  );
}
