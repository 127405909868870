import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { register } from '../services/authService';
import Loader from '../components/Loader';
import axios from 'axios'; // Для загрузки списка пользователей

const theme = createTheme();

export default function Register() {
  const navigate = useNavigate();
  const [role, setRole] = React.useState('user');
  const [username, setUsername] = React.useState('');
  const [team, setTeam] = React.useState([]); // Массив пользователей для команды
  const [allUsers, setAllUsers] = React.useState([]); // Все пользователи для выбора
  const [loading, setLoading] = React.useState(false);

  // Загрузка списка всех пользователей при загрузке компонента
  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://backend.moorpan.com/get-users'); // Используем новый эндпоинт
        setAllUsers(response.data); // Предполагается, что данные возвращаются в виде массива объектов с `id` и `username`
      } catch (error) {
        console.error('Ошибка при загрузке списка пользователей:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    try {
      await register(email, password, role, username, role === 'tlead' ? team : null);
      navigate('/login');
    } catch (error) {
      alert('Registration failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {loading && <Loader />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="role-select-label">Role</InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={role}
                    label="Role"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="tlead">Team Lead</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {role === 'tlead' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="team-select-label">Select Team Members</InputLabel>
                    <Select
                      labelId="team-select-label"
                      id="team-select"
                      multiple
                      value={team}
                      onChange={(e) => setTeam(e.target.value)}
                      renderValue={(selected) =>
                        selected.map((id) => {
                          const user = allUsers.find((user) => user.id === id);
                          return user ? user.username : id;
                        }).join(', ')
                      }
                    >
                      {allUsers.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
